import { differenceInDays } from 'date-fns'
import { useRouter } from 'next/dist/client/router'
import React, { useEffect, useRef, useState } from 'react'
import { useAuthService } from '@/services/auth/auth.service'
import getConfig from 'next/config'
import { useNotiContext } from '@shared/Notification'
import { LoginForm, LoginMethod, ThanksLoginModal } from '@components/login'
import { fireNextState, SubscribeStateKey } from '@helpers/stateSubscriber'
import Loading from '@shared/Loading'
import Header from '@shared/Header'
import Footer from '@shared/Footer'
import css from 'styled-jsx/css'
import { shopHelpPath } from '@/helpers'

function getDaysLeft() {
  const now = new Date()
  return differenceInDays(now, new Date('02/17/2021'))
}

export const HomePageKYC: React.FC = () => {
  const isComingSoon = true
  const router = useRouter()
  const { query } = router
  const [showWelcome, setShowWelcome] = useState<boolean>(true)
  const [openThanksLogin, setOpenThanksLogin] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { login } = useAuthService(getConfig().publicRuntimeConfig.clientApi)
  const { notiDispatch } = useNotiContext()

  const emailRef = useRef<HTMLInputElement>(null)

  let domain = ''

  if (process.browser) {
    domain = window.location.hostname
  }

  function toWelcomeSection() {
    setShowWelcome(true)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    setShowWelcome(!!query.isTestLogin) // TODO: Temp for locked homepage
  }, [])

  async function onLogin() {
    setIsLoading(true)
    const email = emailRef.current?.value
    const result = await login({
      email,
      send_sms: false,
      redirect_url: `https://${domain}/list`
    }).then(
      () => true,
      () => {
        notiDispatch({
          payload: {
            type: 'is-danger',
            title: 'Something went wrong',
            content: "We couldn't find your email!"
          },
          type: 'REMOVE_ALL_AND_ADD'
        })
        return false
      }
    )
    setIsLoading(false)

    result &&
      router.push({ pathname: 'login', query: { method: LoginMethod.CODE } }).then(() => {
        notiDispatch({ type: 'REMOVE_ALL' })
        fireNextState(SubscribeStateKey.LOGIN_FORM, { email })
      })
  }

  return (
    <>
      <style jsx>{pageStyle}</style>
      {isLoading && <Loading />}
      {!isComingSoon ? (
        <LoginForm />
      ) : (
        <>
          <Header />
          <div className="container ">
            {!showWelcome && (
              <>
                <div className="welcome-section m-top is-flex">
                  <img
                    src={shopHelpPath('shophelp-welcome.png')}
                    alt="Welcome"
                    // width={279}
                    // height={584}
                    className="welcome-section__image is-hidden-touch"
                  />
                  <div className="welcome-section__content" id="ShopHelp-welcome">
                    <p className="welcome-section__title mb-6">Welcome to ShopHelp™</p>
                    <p className="welcome-section__subtitle mb-4">
                      ShopHelp™ deliver ecommerce deals and customer service excellence to over
                      200,000 customers from 50 online retailers, and counting!
                    </p>
                    <p className="mb-8">
                      A new version of our website is being diligently worked on. Just a few more
                      days and you will be able to access product pages at super deals, get live
                      updates on your orders, submit requests and communicate with us in an
                      interactive way! Mark your calendar for <b>Jan 31st, 2021</b> for new deals on
                      this site!
                    </p>
                    <p className="mb-8">
                      If you are a customer, know that we back up our claim for hassle free shopping
                      with premium customer experience. Contact us 24/7 via email, forms, or
                      toll-free. We are always open!
                    </p>
                    <p className="mb-8">
                      If you are a store owner, please reach us at our corporate office to enroll,
                      or to request additional services.
                    </p>
                    <p>
                      We look forward to serving you and enhancing your experience with us! Stay
                      tuned!
                    </p>
                  </div>
                  <button
                    className="delete"
                    aria-label="close"
                    onClick={() => setShowWelcome(false)}
                  />
                </div>

                <p className="title mb-6">Find your order here in T{getDaysLeft()} days!</p>

                <div className="login-form">
                  <form onSubmit={(e) => e.preventDefault()} className="section-wrapper">
                    <div className="field">
                      <div className="control">
                        <input className="input" type="email" placeholder="Email..." />
                      </div>
                    </div>

                    <div className="field">
                      <div className="control mb-6">
                        <input type="text" placeholder="Order Number..." className="input" />
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="button is-primary is-fullwidth-mobile"
                      onClick={() => setOpenThanksLogin(true)}
                    >
                      Find it now
                    </button>
                  </form>
                </div>
              </>
            )}

            {showWelcome && (
              <>
                <p className="title mb-3" style={{ marginTop: '8rem' }}>
                  Can we help you?
                </p>

                <div className="login-form mb-8">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault()
                      onLogin()
                    }}
                    className="section-wrapper"
                  >
                    <div className="field mb-8">
                      <div className="control">
                        <input
                          className="input"
                          type="email"
                          placeholder="Email..."
                          required
                          ref={emailRef}
                        />
                      </div>
                    </div>

                    <button type="submit" className="button is-primary is-fullwidth-mobile mb-4">
                      Login
                    </button>

                    <p
                      className="is-link mt-8 mb-4"
                      onClick={() =>
                        router.push({ pathname: 'login', query: { method: LoginMethod.PHONE } })
                      }
                    >
                      Login by phone number
                    </p>
                    <p>
                      Forgot your order information?
                      <span className="ml-4 is-link" onClick={() => router.push('/contact/forgot')}>
                        Contact Us
                      </span>
                    </p>
                  </form>
                </div>
              </>
            )}

            <div className="faqs">
              <div className="section-wrapper">
                <p className="title mb-6" style={{ marginTop: '8rem' }}>
                  Click on the one of most common inquiries below
                </p>
                <div className="faqs__items">
                  <div className="tag" onClick={toWelcomeSection}>
                    <p>I haven&apos;t received a shipment notification.</p>
                  </div>
                  <div className="tag" onClick={toWelcomeSection}>
                    <p>Can I change or cancel my order?</p>
                  </div>
                  <div className="tag" onClick={toWelcomeSection}>
                    <p>What is the status of my order?</p>
                  </div>
                  <div className="tag" onClick={toWelcomeSection}>
                    <p>Can I return an item if I don&apos;t like it?</p>
                  </div>
                  <div className="tag" onClick={toWelcomeSection}>
                    <p>Can I exchange an item?</p>
                  </div>
                  <div className="tag is-hidden-touch" onClick={toWelcomeSection}>
                    <p>Where is my order?</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="static-contents m-top">
              <div className="section-wrapper columns is-multiline">
                <div className="column static-contents__item" onClick={toWelcomeSection}>
                  <div className="image-wrapper mb-8">
                    <img
                      alt="Shipping Policy"
                      src={shopHelpPath('shipping-policy.png')}
                      className="static-contents__item-image"
                    />
                  </div>
                  <p className="title mt-0 mb-0 has-text-left m-top">Shipping Information</p>
                  <p className="mt-2 subtitle">Our warehouse location</p>
                  <p className="mt-2 subtitle">Order processing time</p>
                  <p className="mt-2 subtitle">Shipping time frame</p>
                </div>
                <div className="column static-contents__item" onClick={toWelcomeSection}>
                  <div className="image-wrapper mb-8">
                    <img
                      alt="Shipping Policy"
                      src={shopHelpPath('product-policy.png')}
                      className="static-contents__item-image"
                    />
                  </div>
                  <p className="title mt-0 mb-0 has-text-left m-top">Refund Policy</p>
                  <p className="mt-2 subtitle">Return</p>
                  <p className="mt-2 subtitle">Exchange</p>
                  <p className="mt-2 subtitle">Cancellation</p>
                </div>
                <div className="column static-contents__item" onClick={toWelcomeSection}>
                  <div className="image-wrapper mb-8">
                    <img
                      alt="Shipping Policy"
                      src={shopHelpPath('payment-method.png')}
                      className="static-contents__item-image"
                    />
                  </div>
                  <p className="title mt-0 mb-0 has-text-left m-top">Payment Methods</p>
                  <p className="mt-2 subtitle">Method accepted</p>
                  <p className="mt-2 subtitle">
                    What should I do when my payments won&apos;t go through?
                  </p>
                </div>
                <div className="column static-contents__item" onClick={toWelcomeSection}>
                  <div className="image-wrapper mb-8">
                    <img
                      alt="Shipping Policy"
                      src={shopHelpPath('term-service.png')}
                      className="static-contents__item-image"
                    />
                  </div>
                  <p className="title mt-0 mb-0 has-text-left m-top">Terms of service</p>
                  <p className="mt-2 subtitle">
                    Accuracy, completeness and timeliness of information
                  </p>
                  <p className="mt-2 subtitle">User comments and feedback</p>
                  <p className="mt-2 subtitle ">Changes to terms of service</p>
                </div>
              </div>
            </div>
          </div>
          <Footer className="m-top" />
        </>
      )}
      <ThanksLoginModal open={openThanksLogin} setOpen={setOpenThanksLogin} />
    </>
  )
}

const pageStyle = css`
  .welcome-section {
    line-height: 32px;

    padding: 1.5rem;
    border-radius: var(--round-16);
    box-shadow: 2px 4px 40px rgba(161, 178, 203, 0.25);

    &__title {
      font-size: 24px;
      font-weight: 600;
      line-height: 40px;
      color: var(--gray-18);
    }

    &__subtitle {
      font-size: 16px;
      color: var(--secondary-100);
      font-weight: 600;
    }

    &__content {
      margin-left: 4rem;
      margin-right: 3rem;
      @media screen and (max-width: 768px) {
        margin-left: 1rem;
        margin-right: 0;
      }
    }

    &__image {
      width: 100%;
    }
  }

  .section-wrapper {
    @media screen and (max-width: 768px) {
      max-width: 90%;
      margin: 0 auto;
    }
    padding: 1.25rem;
  }

  p.title {
    text-align: center;
    font-size: 18px;
    line-height: 32px;
    font-weight: 500;
    margin-top: 5%;
    @media screen and (max-width: 768px) {
      margin-top: 15%;
    }
  }

  .login-form {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    form {
      width: 450px;
      text-align: center;

      input {
        height: 48px;
      }

      button {
        min-width: 242px;
        max-width: 450px;
      }
    }
  }

  .faqs {
    text-align: center;

    button {
      min-width: 242px;
    }

    &__items {
      text-align: center;

      .tag {
        background: var(--indigo-2);
        height: 100%;
        padding: 8px 24px;
        margin: 0 16px 8px 8px;
        white-space: normal;
        font-size: 15px;
        line-height: 32px;

        cursor: pointer;
      }
    }
  }

  .static-contents {
    @media screen and (max-width: 768px) {
      margin-top: 5%;
    }

    &__item {
      min-width: 200px;
      text-align: left;
      cursor: pointer;

      .image-wrapper {
        height: 120px;
        display: flex;
        align-items: flex-end;
      }

      &-image {
        margin-top: auto;
      }

      .subtitle {
        font-size: 12px;
        line-height: 24px;
        color: var(--indigo-7);
        margin-bottom: 0;
      }
    }
  }
`

import { NextPage } from 'next'
import { HomePageHeader } from '@components/home'
import Footer from '@shared/Footer'
import { HomePageContainer } from '@/containers/home'

const HomePage: NextPage = () => {
  return (
    <>
      <HomePageHeader />
      <HomePageContainer />
      <Footer className="m-top" />
    </>
  )
}

export default HomePage
